module.exports = [{
      plugin: require('../node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":500},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/portfolio"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Saurabh Dutta","short_name":"SD","description":"Personal portfolio site of Saurabh Dutta","lang":"en","start_url":"/","background_color":"#00000","theme_color":"#ffffff","display":"standalone","icon":"src/assets/images/logo.png","icons":[{"src":"src/assets/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"76385cadd1b7d029605840a8ad7b76de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-62661207-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":400,"defer":true,"exclude":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
